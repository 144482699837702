<template>
  <div class="amui-chip" :class="classes" @click="onClick">
    {{ label }}
    <amui-icon
      v-if="iconName"
      :name="iconName"
      class="amui-chip__icon"
      @click.native="onIconClick"
    />
  </div>
</template>

<script>
import { AmuiIcon } from './../icon'

export default {
  name: 'AmuiChip',

  components: {
    AmuiIcon
  },

  props: {
    label: {
      required: true,
      type: String
    },

    type: {
      type: String,
      required: false,
      default: 'default'
    },

    selectable: {
      type: Boolean,
      required: false,
      default: false
    },

    large: {
      type: Boolean,
      required: false,
      default: false
    },

    selected: {
      type: Boolean,
      required: false,
      default: false
    },

    icon: {
      type: String,
      required: false,
      default: null
    },

    removable: {
      type: Boolean,
      required: false,
      default: false
    },

    clickable: {
      type: Boolean,
      required: false,
      default: false
    },

    color: {
      type: String,
      required: false
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  computed: {
    classes() {
      return {
        'amui-chip--selected': this.selected && this.selectable,
        'amui-chip--has-right-icon':
          (this.selected && this.selectable) || this.removable || this.icon,
        'amui-chip--selectable': this.selectable,
        'amui-chip--clickable': this.clickable, // used in chip cloud
        ['amui-chip--' + this.type]: this.type && !this.selectable,
        'amui-chip--disabled': this.disabled,
        'amui-chip--large': this.isLarge
      }
    },
    iconName() {
      return (
        this.icon ||
        (this.removable
          ? 'close'
          : this.selectable && this.selected
          ? 'check'
          : null)
      )
    },
    isLarge() {
      return this.large || this.selectable || this.removable || this.icon
    }
  },

  methods: {
    onIconClick() {
      if (this.removable) this.$emit('removed')
    },
    onClick() {
      if (this.selectable) this.$emit('update:selected', !this.selected)
      if (this.clickable) this.$emit('click')
    }
  }
}
</script>
