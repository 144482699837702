<template>
  <div
    class="amui-select-wrapper"
    ref="selectbox"
    :class="{
      'amui-select-wrapper--disabled': disabled,
      'amui-select-box--active': isOpen,
      'amui-select-box--no-label': !hasLabel,
      'amui-select-box--focused': focused,
      'amui-select-box--invalid': invalid,
      'amui-select-box--responsive': viewport === null
    }"
  >
    <div class="amui-select-box--native">
      <select
        ref="native-select"
        class="amui-select"
        v-model="nativeSelectModel"
        :disabled="disabled"
        @focus="focused = true"
        @blur="focused = false"
        @change="onNativeSelect"
      >
        <option
          v-for="(option, index) in options"
          :key="index + option.value + option.label"
          :value="JSON.stringify(option.value)"
        >
          {{ option.label }}
        </option>
      </select>
    </div>
    <div class="amui-select-box--custom" ref="custom-select">
      <div
        class="amui-select-box__header"
        @click="collapseOption"
        v-click-outside="hide"
      >
        {{ selectedLabel }}
        <input
          class="amui-select-box--custom__input"
          @focus="
            () => {
              focused = true
              isOpen = false
            }
          "
          @blur="focused = false"
          type="text"
          @keyup="handleKeyDown"
          :aria-label="ariaLabel || label"
        />
      </div>
      <ul class="amui-select-box__options">
        <li
          class="amui-select-box__option-item"
          v-for="(option, index) in options"
          :class="{
            'amui-select-box__option-item--active': option.value === value,
            'amui-select-box__option-item--press': index === indexOfSelected
          }"
          :key="index"
          @click="onSelect(option.value)"
        >
          <span>{{ option.label }}</span>
          <amui-icon name="check" v-if="option.value === value" />
        </li>
      </ul>
    </div>
    <div class="amui-select__arrow" @click="collapseOption">
      <amui-icon name="arrow-drop-down" />
    </div>
    <label
      v-if="hasLabel"
      class="amui-select__label"
      :class="
        isLabelSmall ? 'amui-select__small-label' : 'amui-select__large-label'
      "
      ><slot name="selected">{{ label }}</slot></label
    >
  </div>
</template>

<script>
import { AmuiIcon } from './../icon'
import ClickOutside from 'vue-click-outside'
export default {
  name: 'AmuiSelect',

  components: {
    AmuiIcon
  },

  data() {
    return {
      focused: false,
      nextValue: undefined,
      isOpen: false,
      internalValue: this.value
    }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    label: {
      type: String,
      required: false
    },
    ariaLabel: {
      type: String,
      required: false,
      default: null
    },
    value: {
      type: [String, Number],
      required: false,
      default: null
    },
    options: {
      type: Array,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideLabelWhenSelected: {
      type: Boolean,
      required: false,
      default: false
    },
    invalid: {
      type: Boolean,
      default: false
    },
    viewport: {
      type: String,
      default: null,
      validate(value) {
        return ['s'].includes(value)
      }
    }
  },
  computed: {
    nativeSelectModel: {
      set() {},
      get() {
        // workaround, it only works with an v-model as vue will do some magic to enable to have nothing selected.
        // set v-model with undefined will result in an change event with value set to empty string
        return this.internalValue === null
          ? undefined
          : JSON.stringify(this.internalValue)
      }
    },
    isLabelSmall() {
      if (
        (this.internalValue !== undefined && this.internalValue !== null) ||
        this.focused
      ) {
        return true
      } else {
        return false
      }
    },
    selectedLabel() {
      return this.options.filter(item => this.internalValue === item.value)[0]
        ? this.options.filter(item => this.internalValue === item.value)[0]
            .label
        : ''
    },
    indexOfSelected() {
      let selected = -1
      this.options.forEach((element, ind) => {
        if (this.nextValue === element.value) {
          selected = ind
        }
      })
      return selected
    },
    hasLabel() {
      return this.label &&
        this.label !== '' &&
        (this.hideLabelWhenSelected === false || this.internalValue === null)
        ? true
        : false
    }
  },
  methods: {
    collapseOption() {
      if (this.disabled) return false
      this.nextValue = undefined
      this.isOpen = !this.isOpen
    },
    handleKeyDown(e) {
      if (e.keyCode === 32 && !this.isOpen) {
        this.nextValue = this.options[0].value
        this.isOpen = true
      } else {
        if (e.keyCode === 32 && this.isOpen) {
          this.onSelect(this.nextValue)
          this.$refs.selectbox?.classList.remove('amui-select-box--active')
        }
      }
    },
    keyDown(e) {
      if (
        !this.$refs.selectbox?.classList.value.includes(
          'amui-select-box--active'
        )
      )
        return false

      let i = this.indexOfSelected

      switch (e.keyCode) {
        case 38:
          i -= 1
          if (i < 0) {
            i = this.options.length - 1
          }
          this.nextValue = this.options[i].value
          e.preventDefault()
          break
        case 40:
          i += 1
          if (i > this.options.length - 1) {
            i = 0
          }
          this.nextValue = this.options[i].value
          e.preventDefault()
          break
        case 13:
          if (this.indexOfSelected !== -1 && this.nextValue !== -1) {
            this.onSelect(this.nextValue)
          }
          this.$refs.selectbox?.classList.remove('amui-select-box--active')
          break
        default:
          break
      }
    },
    onNativeSelect(event) {
      // TODO: Refactoring, this onNativeSelect will be also triggered when custom select changes to "Auswahl zurücksetzen"
      // this seems to be a problem with the internal data states, native select with v-model will change unknown values to undefined
      // seems to be fixed with the following line, removed the v-model, instead did :value and @change manually
      // comment can be removed when stable in production
      const value = event.target.value

      if (value !== '') {
        this.internalValue = JSON.parse(value)

        this.$emit('change', this.internalValue)
        this.focused = false
        this.hide()
      }
    },
    onSelect(value) {
      this.$emit('change', value)
      this.hide()
    },
    hide() {
      this.isOpen = false
    }
  },
  directives: {
    ClickOutside
  },
  watch: {
    value(value) {
      this.internalValue = value
    }
  },
  mounted() {
    this.popupItem = this.$refs['custom-select']
    this.nextValue = this.internalValue
    window.addEventListener('keydown', this.keyDown)
  },
  beforeDestroy() {
    window.addEventListener('keydown', this.keyDown)
  }
}
</script>
