var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"amui-check-box",class:{
    'amui-check-box--invalid': _vm.invalid,
    'amui-check-box--disabled': _vm.disabled,
    'amui-check-box--responsive': _vm.viewport === null,
    'amui-check-box--checked': _vm.isChecked
  }},[_c('div',{staticClass:"amui-check-box__wrapper",class:{ hover: _vm.focused }},[_c('div',{staticClass:"amui-check-box__inner"},[_c('div',{staticClass:"amui-check-box__icon",class:{
          'amui-check-box__icon--default': _vm.isChecked
        },style:(_vm.colorStyle),on:{"click":function($event){$event.stopPropagation();return _vm.handleSelect.apply(null, arguments)}}},[_c('div',{staticClass:"amui-check-box__icon--effect",class:{ 'amui-check-box__icon--effect--active': _vm.isChecked }},[_c('amui-icon',{attrs:{"name":"check","size":"sm","viewport":_vm.viewport}})],1),_c('div',{staticClass:"amui-check-box__icon-focus"},[_c('div',{staticClass:"amui-check-box__focus-ring",style:({ backgroundColor: _vm.borderColor })})])]),_c('input',{staticClass:"amui-check-box__input",attrs:{"type":"checkbox","id":_vm.componentId},domProps:{"value":_vm.val},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"change":_vm.handleSelect}})]),_c('label',{staticClass:"amui-check-box__label",attrs:{"for":_vm.componentId}},[_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]})],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }