var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"selectbox",staticClass:"amui-select-wrapper",class:{
    'amui-select-wrapper--disabled': _vm.disabled,
    'amui-select-box--active': _vm.isOpen,
    'amui-select-box--no-label': !_vm.hasLabel,
    'amui-select-box--focused': _vm.focused,
    'amui-select-box--invalid': _vm.invalid,
    'amui-select-box--responsive': _vm.viewport === null
  }},[_c('div',{staticClass:"amui-select-box--native"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.nativeSelectModel),expression:"nativeSelectModel"}],ref:"native-select",staticClass:"amui-select",attrs:{"disabled":_vm.disabled},on:{"focus":function($event){_vm.focused = true},"blur":function($event){_vm.focused = false},"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.nativeSelectModel=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},_vm.onNativeSelect]}},_vm._l((_vm.options),function(option,index){return _c('option',{key:index + option.value + option.label,domProps:{"value":JSON.stringify(option.value)}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0)]),_c('div',{ref:"custom-select",staticClass:"amui-select-box--custom"},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.hide),expression:"hide"}],staticClass:"amui-select-box__header",on:{"click":_vm.collapseOption}},[_vm._v(" "+_vm._s(_vm.selectedLabel)+" "),_c('input',{staticClass:"amui-select-box--custom__input",attrs:{"type":"text","aria-label":_vm.ariaLabel || _vm.label},on:{"focus":function () {
            _vm.focused = true
            _vm.isOpen = false
          },"blur":function($event){_vm.focused = false},"keyup":_vm.handleKeyDown}})]),_c('ul',{staticClass:"amui-select-box__options"},_vm._l((_vm.options),function(option,index){return _c('li',{key:index,staticClass:"amui-select-box__option-item",class:{
          'amui-select-box__option-item--active': option.value === _vm.value,
          'amui-select-box__option-item--press': index === _vm.indexOfSelected
        },on:{"click":function($event){return _vm.onSelect(option.value)}}},[_c('span',[_vm._v(_vm._s(option.label))]),(option.value === _vm.value)?_c('amui-icon',{attrs:{"name":"check"}}):_vm._e()],1)}),0)]),_c('div',{staticClass:"amui-select__arrow",on:{"click":_vm.collapseOption}},[_c('amui-icon',{attrs:{"name":"arrow-drop-down"}})],1),(_vm.hasLabel)?_c('label',{staticClass:"amui-select__label",class:_vm.isLabelSmall ? 'amui-select__small-label' : 'amui-select__large-label'},[_vm._t("selected",function(){return [_vm._v(_vm._s(_vm.label))]})],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }