<template>
  <div
    class="amui-check-box"
    :class="{
      'amui-check-box--invalid': invalid,
      'amui-check-box--disabled': disabled,
      'amui-check-box--responsive': viewport === null,
      'amui-check-box--checked': isChecked
    }"
  >
    <div class="amui-check-box__wrapper" :class="{ hover: focused }">
      <div class="amui-check-box__inner">
        <div
          class="amui-check-box__icon"
          :style="colorStyle"
          :class="{
            'amui-check-box__icon--default': isChecked
          }"
          @click.stop="handleSelect"
        >
          <div
            class="amui-check-box__icon--effect"
            :class="{ 'amui-check-box__icon--effect--active': isChecked }"
          >
            <amui-icon name="check" size="sm" :viewport="viewport" />
          </div>
          <div class="amui-check-box__icon-focus">
            <div
              class="amui-check-box__focus-ring"
              :style="{ backgroundColor: borderColor }"
            ></div>
          </div>
        </div>
        <input
          type="checkbox"
          @focus="focused = true"
          @blur="focused = false"
          class="amui-check-box__input"
          :value="val"
          :id="componentId"
          @change="handleSelect"
        />
      </div>
      <label class="amui-check-box__label" :for="componentId">
        <slot name="label">{{ label }}</slot>
      </label>
    </div>
  </div>
</template>

<script>
import { AmuiIcon } from './../icon'

export default {
  name: 'AmuiCheckBox',

  components: {
    AmuiIcon
  },

  model: {
    prop: 'value',
    event: 'change'
  },

  props: {
    value: {
      type: [Number, String, Array, Boolean],
      required: true
    },
    label: {
      type: String,
      required: false
    },
    val: {
      type: [Number, String, Array, Boolean],
      required: false
    },
    'true-value': {
      type: Boolean,
      default: true
    },
    'false-value': {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      required: false,
      default: null
    },
    borderColor: {
      type: String,
      required: false,
      default: null
    },
    color: {
      type: String,
      required: false,
      default: null
    },
    invalid: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkableWhenDisabled: {
      type: Boolean,
      default: false
    },
    viewport: {
      type: String,
      default: null,
      validate(value) {
        return ['s'].includes(value)
      }
    }
  },
  data() {
    return {
      focused: false,
      componentId: null
    }
  },

  created() {
    this.componentId = 'amui-checkbox-' + this.$am.id.get()
  },

  computed: {
    isChecked() {
      let result = false
      if (Array.isArray(this.value)) {
        result = this.value.includes(this.val)
      } else {
        switch (typeof this.value) {
          case 'boolean':
            result = this.value
            break
          default:
            result = this.value === this.val
            break
        }
      }

      return result
    },
    colorStyle() {
      return {
        backgroundColor: this.backgroundColor,
        color: this.color,
        borderColor: this.borderColor
      }
    }
  },

  methods: {
    handleSelect() {
      if (this.disabled && !this.checkableWhenDisabled) return

      let _value = null
      if (this.isChecked) {
        if (Array.isArray(this.value)) {
          const i = this.value.indexOf(this.val)
          if (i !== -1) {
            _value = this.value.filter(v => v !== this.val)
          }
        } else {
          switch (typeof this.value) {
            case 'boolean':
              _value = false
              break
            default:
              _value = null
              break
          }
        }
      } else {
        if (Array.isArray(this.value)) {
          _value = this.value.concat(this.val)
        } else {
          switch (typeof this.value) {
            case 'boolean':
              _value = true
              break
            default:
              _value = this.val
              break
          }
        }
      }
      this.$emit('change', _value)
    }
  }
}
</script>
